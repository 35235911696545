<template>
  <div class="caseDetail">
    <PageTitle ttl-en="CASE" ttl-jp="_想定課題" />
    <div class="inner">
      <p class="caseDetail__mv js-scroll">
        <img src="/genbashiko/img/case/case06/mv.jpg" alt="" />
      </p>
      <section class="caseDetail__outline">
        <h1 class="js-scroll">
          1つの塗装検査ラインで様々な塗色のワークに合った
          <br />照明の明るさや光源色で検査ができない
        </h1>
        <p class="caseDetail__outline__txt js-scroll">
          自動車製造の塗装検査工程では、様々な塗色のワークが1つの検査ラインに流れていきます。具体的な手法として一般的なのが、蛍光灯やLEDを用いたゼブラ照明です。調光機能がついているゼブラ照明を導入していても、常に動いている検査ラインでは、手動で明るさを切り替えると、タクトタイム(1つの製品製造にかける時間)や作業員の目の疲労などにつながるため、決まった明るさに設定するケースがほとんどです。
        </p>
        <div class="caseDetail__outline__cate js-scroll">
          <p>担当領域:</p>
          <ul>
            <li>
              <router-link
                class="category"
                :to="{ name: 'Case', query: { category: '新たに検査現場を構築したい' }}"
              >
                新たに検査現場を構築したい
              </router-link>
            </li>
            <li>
              <router-link
                class="category"
                :to="{ name: 'Case', query: { category: '既存の検査場を改修したい' }}"
              >
                既存の検査場を改修したい
              </router-link>
            </li>
            <li>
              <router-link
                class="category"
                :to="{ name: 'Case', query: { category: '検査現場を省人化したい' }}"
              >
                検査現場を省人化したい
              </router-link>
            </li>
            <li>
              <router-link
                class="category"
                to="/case?category=コンサルティング"
              >
                コンサルティング
              </router-link>
            </li>
            <li>
              <router-link class="category" to="/case?category=製品開発">
                製品開発
              </router-link>
            </li>
            <li>
              <router-link class="category" to="/case?category=納品後サポート">
                納品後サポート
              </router-link>
            </li>
          </ul>
        </div>
      </section>
      <section class="caseDetail__env">
        <h3 class="caseDetail__env__ttl js-scroll">
          想定される環境
        </h3>
        <ul class="js-scroll">
          <li>目視車体塗装後検査ライン</li>
          <li>目視樹脂塗装後検査ライン</li>
          <li>画像車体塗装後検査ライン</li>
          <li>画像樹脂塗装後検査ライン</li>
        </ul>
      </section>
      <section class="caseDetail__ex">
        <h3 class="caseDetail__ex__ttl js-scroll">
          想定課題と具体的な改善施策例
        </h3>
        <div class="caseDetail__ex__problem js-scroll">
          <div class="caseDetail__ex__problem__p">
            <p class="ttl">
              課題
            </p>
            <p class="txt">
              目視検査工程で黒色ワークが見やすい照明の明るさに設定すると、白色ワークが流れてきたときに明るさが足りず見えにくい
            </p>
          </div>
          <div class="caseDetail__ex__problem__a">
            <span class="ttl">施策</span>
            <p class="txt">
              自動調色調光システムを導入する
            </p>
          </div>
          <div class="caseDetail__ex__problem__img">
            <p>
              <img src="/genbashiko/img/case/case06/image01.png" alt="" />
            </p>
            <p>
              <img src="/genbashiko/img/case/case06/image02.png" alt="" />
            </p>
          </div>
          <p class="caseDetail__ex__problem__cap">
            自動調色調光システムでは、まず、ワークの塗装色ごとに適切な照度・光源色を出力できるように設定し、カメラで対象ワークを撮像することで、あらかじめ設定しておいた塗装色を判別し、照明の明るさ・色味を自動で変化させることができます。それによって 「眩しい」「暗い」といった目やカメラでの見え方が改善され、検査員の目の疲労軽減やタクトタイムの短縮につながります。
          </p>
        </div>
        <p class="caseDetail__ex__download js-scroll">
          <a href="/genbashiko/upload/download_pdf/PA-jido-dimming.pdf" target="_blank"
            >課題に関する資料ダウンロード</a
          >
        </p>
      </section>
    </div>
  </div>
</template>
<script>
import PageTitle from '@/components/PageTitle.vue'

export default {
  components: {
    PageTitle
  },
  head: {
    title: {
      inner: 'Case06'
    },
    meta: [
      { property: 'og:title', content: 'Case06｜GENBA SHIKO' },
      {
        name: 'description',
        content:
          '1つの塗装検査ラインで様々な塗色のワークに合った照明の明るさや光源色で検査ができない環境には、自動調色調光システムを導入して労働環境とタクトタイムの改善をサポートします。'
      },
      {
        property: 'og:description',
        content:
          '1つの塗装検査ラインで様々な塗色のワークに合った照明の明るさや光源色で検査ができない環境には、自動調色調光システムを導入して労働環境とタクトタイムの改善をサポートします。'
      },
      {
        property: 'og:url',
        content: 'https://luci.co.jp/genbashiko/case/case06'
      },
      { property: 'og:type', content: 'article' }
    ]
  }
}
</script>
