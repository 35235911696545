import { render, staticRenderFns } from "./Case06.vue?vue&type=template&id=4df7ddf8"
import script from "./Case06.vue?vue&type=script&lang=js"
export * from "./Case06.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports